var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tea-type-container" }, [
    _vm.showTitle
      ? _c("div", { staticClass: "tea-type-title" }, [
          _c("div", { staticClass: "type-title" }, [
            _vm._v(_vm._s(_vm.item.categoryName)),
          ]),
          _c(
            "div",
            {
              staticClass: "more-list",
              on: {
                click: function ($event) {
                  return _vm.linkList(_vm.item)
                },
              },
            },
            [_vm._v("更多"), _c("i", { staticClass: "el-icon-arrow-right" })]
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "tea-type-list-container" },
      _vm._l(_vm.item.list, function (k) {
        return _c(
          "div",
          {
            key: k.id,
            staticClass: "tea-type-list",
            on: {
              click: function ($event) {
                return _vm.linkDetail(k)
              },
            },
          },
          [
            _c("img", {
              staticClass: "list-image",
              attrs: {
                src:
                  k.picture || "/images/01E48904F4CF61B68B8056BDF39F25ED.jpg",
                alt: "",
              },
            }),
            _c("div", { staticClass: "list-infor" }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(k.title))]),
              _c("div", { staticClass: "tips" }, [
                _vm._v(_vm._s(k.tags.join(","))),
              ]),
              _c("div", { staticClass: "price" }, [
                _vm._v(_vm._s((k.salePrice / 100).toFixed(2))),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }