var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Banner"),
      _c(
        "div",
        _vm._l(_vm.dataList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "md-bg-wrap" },
            [_c("TeaTypeList", { attrs: { item: item } })],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }