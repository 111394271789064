var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _c(
        "el-carousel",
        { attrs: { height: "450px", arrow: "never" } },
        [
          _c("el-carousel-item", [
            _c("div", [
              _c("img", { attrs: { src: _vm.banner1, alt: "banner" } }),
            ]),
          ]),
          _c("el-carousel-item", [
            _c("div", [
              _c("img", { attrs: { src: _vm.banner2, alt: "banner" } }),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "search-container" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("el-input", {
              staticClass: "search-input",
              attrs: { placeholder: "7572普洱茶" },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.$showNoAuthMsg()
                  },
                },
              },
              [_vm._v("搜 茶")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "hot-search-box" }, [
          _c(
            "ul",
            { staticClass: "hot-search" },
            [
              _c("li", { staticClass: "hot-search-list" }, [
                _vm._v("热门搜索"),
              ]),
              _vm._l(_vm.searchList, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "hot-search-list hot-hover",
                    on: {
                      click: function ($event) {
                        return _vm.$showNoAuthMsg()
                      },
                    },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }